import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import Plaatjie from '@ubo/plaatjie'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

// Elements
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'

const Section = styled.section`
  & .container {
    @media (max-width: 767px) {
      padding-right: 0 !important;
    }
  }
`

const FilterWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.font.family.primaryMedium};
  height: 100px;

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: ${({ theme }) => theme.color.primary};
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 48px;
    }
  }
`

interface HighlightedProjectsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_HighlightedPosts
}

const HighlightedProjects: React.FC<HighlightedProjectsProps> = ({
  fields,
}) => (
  <Section className="mb-5 pb-lg-5">
    <div className="container">
      <Blog fields={fields} />
    </div>
  </Section>
)

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_HighlightedPosts
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpProject,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedProjectsQueryQuery>(graphql`
    query highlightedProjectsQuery {
      allWpProject(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalProjectFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpProject.edges

  return (
    <BlogBink
      posts={posts as BlogBinkPosts}
      id="highlighted-Projects"
      showIds={fields?.relationprojects?.map(
        ({ databaseId }: any) => databaseId
      )}
      limit={Infinity}
    >
      <div className="row">
        <div className="d-flex">
          <Content content={fields.description} className="me-5 pe-5" />
          <FilterWrapper className="position-relative" />
        </div>
      </div>
      <div className="row d-none d-md-flex">
        <BlogGrid fields={fields} />
      </div>
      <div className="row d-flex d-md-none">
        <BlogGridSlider fields={fields} />
      </div>
    </BlogBink>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: any
}

const BlogGrid: React.FC<BlogGridProps> = () => {
  const blogBink = useBlogBink()

  return (
    <>
      {blogBink.posts.map((post) => {
        const { node }: any = post

        return (
          <React.Fragment key={post.node.id}>
            <div className="col-sm-6 col-md-4 mt-4">
              <BlogGridPost node={node} />
            </div>
          </React.Fragment>
        )
      })}
    </>
  )
}

const SliderWrapper = styled.div`
  &.swiper-wrapper {
    transition-timing-function: linear !important;
  }
  & .swiper-container {
    width: 120% !important;
  }

  & .swiper-slide {
    width: 60%;
  }
`

interface BlogGridSliderProps {
  // eslint-disable-next-line
  fields: any
}

const BlogGridSlider: React.FC<BlogGridSliderProps> = () => {
  const blogBink = useBlogBink()

  return (
    <SliderWrapper className="mt-4">
      <Swiper
        freeMode
        loop
        loopFillGroupWithBlank={false}
        slidesPerView="auto"
        spaceBetween={0}
        speed={5000}
        freeModeMomentum={false}
      >
        {blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <React.Fragment key={post.node.id}>
              <SwiperSlide className="me-4">
                <BlogGridPost node={node} />
              </SwiperSlide>
            </React.Fragment>
          )
        })}
      </Swiper>
    </SliderWrapper>
  )
}

const Post = styled(NavLink)`
  color: ${({ theme }) => theme.color.light};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    font-size: 24px;
  }

  & span {
    font-size: 18px;
  }

  &:hover {
    color: ${({ theme }) => theme.color.light};
  }
  @media (min-width: 1400px) {
    min-height: 425px;
  }
  @media (max-width: 1399px) {
    min-height: 355px;
  }
  @media (max-width: 1199px) {
    min-height: 295px;
  }
  @media (max-width: 991px) {
    min-height: 215px;
  }
  @media (max-width: 767px) {
    min-height: 245px;
  }
  @media (max-width: 575px) {
    min-height: auto;
  }
`

const PostInner = styled.div`
  margin-top: -6.9rem;
  background: rgb(29, 26, 26);
  background: linear-gradient(
    0deg,
    rgba(29, 26, 26, 0.727328431372549) 70%,
    rgba(255, 255, 255, 0) 100%
  ) !important;

  @media (max-width: 991px) {
    & h2 {
      font-size: 18px;
      min-height: 42px;
    }

    & span {
      font-size: 14px;
    }
  }

  @media (max-width: 575px) {
    margin-top: -7.9rem;

    & h2 {
      font-size: 16px;
    }
  }
`

const ImageWrapper = styled(motion.div)`
  bottom: 0;
  left: 0;
  width: 100%;

  @media (min-width: 576px) {
    position: absolute;
  }
  @media (max-width: 575px) {
    position: relative;
  }
`

const Image = styled(Plaatjie)`
  position: relative;
  z-index: -1;
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  node: {
    id: string
    title: string
    uri: string
    projectdetail: {
      highlightedimage: any
    }
    projectcategories: {
      nodes: any
    }
  }
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const [isHover, setHover] = useState<boolean>(false)

  const category = node.projectcategories.nodes[0]

  return (
    <Post
      to={node.uri}
      className="position-relative d-flex flex-column justify-content-end"
    >
      <motion.div
        role="button"
        onHoverStart={() => setHover(true)}
        onHoverEnd={() => setHover(false)}
        className="h-100"
      >
        <ImageWrapper className="h-100 overflow-hidden">
          <motion.div
            initial={{ scale: 1 }}
            animate={isHover ? { scale: 1.1 } : { scale: 1.0 }}
            exit={{ scale: 1 }}
            transition={{ duration: 0.25 }}
            className="h-100"
          >
            <Image
              image={node.projectdetail.highlightedimage}
              alt=""
              className="h-100"
            />
          </motion.div>
        </ImageWrapper>

        <PostInner className="position-relative p-4">
          <h2>{node.title}</h2>
          <span>{category.name}</span>
        </PostInner>
      </motion.div>
    </Post>
  )
}

export default HighlightedProjects
