import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Images
import ArrowRight from 'img/arrowright.inline.svg'
import NavLink from 'components/shared/NavLink'

const Col = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
`

const Row = styled.div`
  min-height: 425px;
  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: ${({ theme }) => theme.color.grey};
  }
`

const Image = styled(Plaatjie)`
  @media (min-width: 1400px) {
    height: 551px;
    width: 461px;
  }
  @media (max-width: 1399px) {
    height: 451px;
    width: 361px;
  }
  @media (max-width: 991px) {
    height: 311px;
    width: 271px;
  }
  @media (max-width: 767px) {
    height: 271px;
    width: 241px;
  }
  @media (min-width: 576px) {
    left: -2px;
    bottom: 0;
  }
  @media (max-width: 575px) {
    height: 131px;
    width: 111px;
    left: -2px;
    bottom: 0;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 48px;
    }
  }
`

interface HighlightedVacanciesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_HighlightedPosts
}

const HighlightedVacancies: React.FC<HighlightedVacanciesProps> = ({
  fields,
}) => (
  <section className="my-5 py-lg-5">
    <div className="container py-5">
      <div className="row justify-content-center align-items-center">
        <Col className="col-lg-10">
          <Row className="row justify-content-end position-relative py-5">
            <Image image={fields.image} className="position-absolute" alt="" />
            <div className="col-sm-7">
              <Content content={fields.description} />
              <div className="h-100 d-flex flex-column justify-content-center ps-5">
                <Blog fields={fields} />
                <div className="mt-3 d-inline-flex justify-content-end justify-content-sm-start">
                  <ButtonPrimary to="/vacatures">
                    Bekijk alle vacatures
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </Row>
        </Col>
      </div>
    </div>
  </section>
)

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_HighlightedPosts
}

const Blog: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpVacancy,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedVacanciesQueryQuery>(graphql`
    query highlightedVacanciesQuery {
      allWpVacancy(sort: { order: DESC, fields: date }) {
        edges {
          node {
            id
            databaseId
            title
            uri
          }
        }
      }
    }
  `)

  const posts: unknown = allWpVacancy.edges

  return (
    <BlogBink
      posts={posts as BlogBinkPosts}
      id="highlighted-Vacancies"
      showIds={fields?.relationvacancy?.map(
        ({ databaseId }: any) => databaseId
      )}
      limit={Infinity}
    >
      <BlogGrid fields={fields} />
    </BlogBink>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: any
}

const BlogGrid: React.FC<BlogGridProps> = () => {
  const blogBink = useBlogBink()

  return (
    <>
      {blogBink.posts.map((post) => {
        const { node }: any = post

        return (
          <React.Fragment key={post.node.id}>
            <BlogGridPost node={node} />
          </React.Fragment>
        )
      })}
    </>
  )
}

const Post = styled(NavLink)`
  background-color: ${({ theme }) => theme.color.light};
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 18px;
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
  }
`

interface BlogGridPostProps {
  node: any
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const [isHover, setHover] = useState<boolean>(false)

  return (
    <motion.div
      onHoverStart={() => setHover(true)}
      onHoverEnd={() => setHover(false)}
    >
      <Post to={node.uri} className="d-flex align-items-center p-3 mb-4">
        <motion.div animate={isHover ? { x: 10 } : { x: 0 }}>
          <ArrowRight className="me-4 mb-1" />
        </motion.div>
        <h2 className="mb-0">{node.title}</h2>
      </Post>
    </motion.div>
  )
}

export default HighlightedVacancies
