import React from 'react'

// Components
import HighlightedVacancies from 'components/flex/Posts/Highlighted/HighlightedVacancies'
import HighlightedProjects from 'components/flex/Posts/Highlighted/HighlightedProjects'

interface HighlightedProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location?: any
}

interface PostProps {
  [key: string]: any
}

const Highlighted: React.FC<HighlightedProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    vacancies: HighlightedVacancies,
    projects: HighlightedProjects,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default Highlighted
